<template>
  <div class="wx-page">
    <div class="info-status">
      <span class="el-icon-success"></span>
      <div class="content">
        <p class="title">支付成功</p>
        <p class="subTitle">感谢您的购买</p>
      </div>
    </div>
    <div class="order-content">
      <div class="top">
        <span class="num">{{ getTotal }}</span>
        <span class="unit">￥</span>
      </div>
      <div class="bottom">
        <span class="detail">订单编号：{{ orderNo }}</span>
        <span class="detail">下单时间：{{ createTime }}</span>
        <span class="detail">支付方式：{{  payType === "wx" ? '微信支付': '支付宝支付' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderDetail } from '@/api/pay'
export default {
  name: 'WxPage',
  data() {
    return {
      payNum: 0,
      payType: 'wx',
      orderNo: null,
      createTime: null,
      orderNo: null
    }
  },
  computed: {
    getTotal() {
      return String(this.payNum).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  mounted() {
    this.payType = this.$route.query.payType || this.payType
    if(this.payType === 'ali') {
      this.payNum = this.$route.query.total_amount
      this.orderNo = this.$route.query.out_trade_no
      this.createTime = this.$route.query.timestamp
    }  else {
      this.orderNo = this.$route.query.orderNo
      this.getOrderDetail()
    }
  },
  methods: {
    async getOrderDetail() {
      const res = await getOrderDetail(this.orderNo)
      if(res.code === 200) {
        this.createTime = res.data.updateTime
        this.payNum = res.data.totalFee / 100
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .wx-page{
    height: 100vh;
    // padding-top: 200px;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #444;
    background-color: #f5f5f5;
    background: url('../Home/components/img/pay/pay-bg.png') no-repeat center/cover;
    .info-status {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 70px;
      &>span {
        font-size: 72px;
        color: #E00390;
        margin-right: 20px;
      }
      .content {
        font-family: Alibaba PuHuiTi;
        color: #333333;
        .title {
          font-weight: 600;
          font-size: 32px;
          margin-bottom: 5px;
        }
        .subTitle {
          font-weight: 400;
          font-size: 22px;
        }
      }
    }
    .order-content {
      width: 577px;
      height: 411px;
      border-radius: 15px;
      border: 1px solid #E00390;
      padding: 30px;
      .top {
        font-family: Alibaba PuHuiTi;
        font-weight: 600;
        font-size: 64px;
        color: #E00390;
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 50px;
        border-bottom: 1px dashed #E00390;
        .unit {
          font-size: 41px;
          margin-left: 18px;
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        font-size: 23px;
        color: #333333;
        gap: 25px;
      }
    }

  }
</style>
